// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
import React from 'react';
import ReactDOM from 'react-dom';
import HeroSection from './HeroSection';
import './App.css';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//    <HeroSection />,
//   </React.StrictMode>
// );
// Wait for the DOM to load before rendering the React component
document.addEventListener('DOMContentLoaded', () => {
    const element = document.querySelector('.react-hero-section');

    if (element) {
        const title = element.getAttribute('data-title');
        const subtitle = element.getAttribute('data-subtitle');
        const backgroundImage = element.getAttribute('data-background-image');
        const buttonLabel = element.getAttribute('data-button-label');
        
        ReactDOM.render(
            <HeroSection 
                title={title} 
                subtitle={subtitle} 
                backgroundImage={backgroundImage} 
                buttonLabel={buttonLabel} 
            />,
            element
        );
    }
});
