import React from "react";
import { TypeAnimation } from "react-type-animation";
import "./HeroSection.css";

const Header = () => {
  const videoURL = "https://realtygroupcostarica.com/wp-content/uploads/2024/10/q-realty-hero-bg-video.mp4";
  const fallbackImageURL = "https://storage.googleapis.com/soluna-mystica-assets/Screenshot%202024-10-30%20at%209.36.18%20PM.png"; // Your fallback image URL
  const logoTitle = "https://realtygroupcostarica.com/wp-content/uploads/2024/10/QR-Full-White-Transparent.png";

  // Create an object that maps location names to URLs
  const locationLinks = {
    Jaco: "https://realtygroupcostarica.com/jaco-costa-rica/",
    Herradura: "https://realtygroupcostarica.com/herradura-costa-rica/",
    "Santo Domingo": "https://realtygroupcostarica.com/santo-domingo-de-heredia-costa-rica/",
    Escazu: "https://realtygroupcostarica.com/escazu-costa-rica/",
    Guacalillo: "https://realtygroupcostarica.com/guacalillo-costa-rica/",
    Bijagual: "https://realtygroupcostarica.com/bijagual-costa-rica/",
  };

  return (
    <div className="header-container-unique">
      {/* Background Video with Fallback Image */}
      <video className="background-video-unique" autoPlay muted loop playsInline poster={fallbackImageURL}>
        <source src={videoURL} type="video/mp4" />
      </video>

      {/* Middle Content */}
      <div className="header-content-unique">
        <div className="logo-container-unique">
          <img src={logoTitle} alt="Realty Group Costa Rica Logo" className="logo-title-unique" />
        </div>

        <div className="welcome-text-unique">
          <TypeAnimation
            className="type-animation-unique"
            sequence={[
              "Welcome to Realty Group Costa Rica", 3000,
              "Explore our available properties below", 3000,
              "Contact us for more information", 4000,
            ]}
            speed={60}
            wrapper="h2"
            cursor={true}
            deletionSpeed={100}
            repeat={Infinity}
          />
        </div>

        <div className="button-group-unique">
          {Object.keys(locationLinks).map((location) => (
            <a key={location} href={locationLinks[location]}>
              <button className="cta-button-unique">{location}</button>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Header;
